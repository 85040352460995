<template>
    <div class="dash-container">
        <h1>
        BILLING
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div class="billing">
        <span v-if="!emsg">
            <p class="org-description is-size-7">To update your payment method or review more granular billing details, visit our payment portal hosted by Stripe with the button below:</p>
            <br>
            <button class="button submit is-primary is-outlined" @click="stripePortal">Payment Portal</button>
        </span>
        <span v-if="umsg" class="error-message">{{ umsg }}</span>
        <span v-if="emsg && !smsg" class="error-message">{{ emsg }}</span>
        <b-table class="billing" 
          striped 
          default-sort-direction="asc" 
          default-sort="name" pack="fa" 
          sort-icon="magnify" 
          sort-icon-size="is-small"
          paginated
          per-page="5"
          :data="data"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
                <template
                    v-if="column.searchable && !column.numeric"
                    slot="searchable"
                    slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        style="padding-left: 0px;"
                        placeholder="Search..."
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                    <div v-if="column.field === 'download'">
                      <a :href='props.row.link'><button class='button mini-button is-primary is-outlined is-success' :id='"download-" + props.index' alt="Accept"><b-icon pack="fa" icon="download"></b-icon></button></a>
                    </div>
                </template>
            </b-table-column>
          </template>
        </b-table>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      smsg: "",
      emsg: "",
      umsg: "",
      data: [],
      columns: [
        {
            field: 'number',
            label: 'Invoice',
            width: 250,
            searchable: true,
        },
        {
            field: 'start',
            label: 'Start',
            width: 200,
            searchable: true,
        },
        {
            field: 'end',
            label: 'End',
            width: 200,
            searchable: true,
        },
        {
            field: 'due',
            label: 'Due',
            width: 150,
        },
        {
            field: "paid",
            label: "Paid",
            width: 150
        },
        {
            field: "status",
            label: "Status",
            width: 50,
        },
        {
            field: "download",
            label: "Download",
            Width: 50,
        }
      ]
    }
  },
  watch: {
    '$props':{
      handler: function (val, oldVal) { 
        console.log('watch', val);
        console.log('watch', oldVal);
        this.message="";
        this.getData();
      },
      deep: true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        var query = this.q;
        console.log("API: " + api);
        console.log("Searching: " + query);
        this.load()
        this.loading = true;

        this.$store.dispatch('auth/get_billing', query).then(
        (response) => {
            console.log(response);
            if (response.data.status == true && response.data.data != "" ) {
                console.log("if loop: ");
                console.log(response.data.invoices);
                this.data = response.data.invoices;
            } else {
              this.emsg = "You have no billing history..."
              console.log("You have no billing history");
              this.data = response.data.invoices;
            }
        },
        error => {
            this.loading = false;
            this.data = [];
            this.emsg = "You have no billing history...";
            console.log(error);
        });

      });
    },
    stripePortal() {
        requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        var query = this.q;
        console.log("API: " + api);
        console.log("Searching: " + query);
        this.load()
        this.loading = true;

        this.$store.dispatch('auth/get_portal', query).then(
        (response) => {
            console.log(response);
            if (response.data.status == true && response.data.session != "" ) {
                console.log("if loop: ");
                console.log(response.data.session.url);
                window.location = response.data.session.url;
            } else {
              this.umsg = "Error in getting portal URL";
              console.log("Error in getting portal URL");
            }
        },
        error => {
            this.loading = false;
            this.data = [];
            this.emsg = "You have no billing history...";
            console.log(error);
        });

      });
    },
    load() {
        let loader = this.$loading.show({
        container: this.fullPage,
        onCancel: this.onCancel,
        active: this.isLoading
        });
        setTimeout(() => {
        loader.hide()
        },1000)
    },
    onCancel() {
        console.log('User cancelled the loader.')
    },
  }
}
</script>
<style lang='scss' scoped>
h1 {
    font-weight: bold;
}
.billing {
  padding-top: 30px;
}
.remove a {
  color: red;
}
.error-message {
  color: red;
  text-align: center;
}
.success-message {
  color: #aac734;
  text-align: right;
}
.mini-button {
  padding: 10px;
  margin-left: 5px;
  height: 15px;
}
</style>